/* eslint-disable */
import {Vue} from 'vue-class-component';
import { Getter, Mutation, State, namespace} from 'vuex-class'
import { Shop } from '@/store/shop/types'
import { ShopOrdering } from '@/components/pages/shop/order/order.interface'
import $ from "jquery";
import * as messageModal from "@/store/message-modal";
import { ValidCredentials, ValidEmail, ValidNickName, ValidPassword } from '@/services/validation.service'
import Dropbox from "@/store/dropbox";

const shop = namespace('shop');
const customer = namespace('customer');

export default class ShopOrderingStep1Page extends Vue {
    @shop.State('shop') shop: Shop | undefined;
    @customer.Getter('basket') basket: any;

    @shop.Action('getDeliveryCountries') getDeliveryCountries: any;
    @shop.Action('getDeliveryTypes') getDeliveryTypes: any;
    @shop.Getter('deliveryCountries') deliveryCountries: any;

    orderData : any = {
		country : {value: "", require: true, class: ""},
		email : {value: "", require: true, class: ""},
		name : {value: "", require: true, class: ""},
		surname : {value: "", require: true, class: ""},

        city : {value: "", require: true, class: ""},
        address : {value: "", require: true, class: ""},
		postcode : {value: "", require: true, class: ""},
	};

    //Тип доставки
    shipping : any = {};
    shippingTypes : any = [];

    //Тип оплаты
    payment : any = {
        type: "ppsd",
        subtype: "",
        name: ""
    };

    get totalPrice() {
        return this.basket.reduce((acc : any, item : any) => acc + (item.price * item.count), 0);
    }

    Dropbox(e : any) {
        Dropbox(e);
    }

    SelectDelivery()
    {
        let list = $('#js-delivery-list');

        if (list.is(":visible"))
            list.slideUp(200);
        else
            list.slideDown(200);
    }

    ChangeCountry()
    {
        let _this = this;

        this.orderData.country.class = ''

        this.getDeliveryTypes(this.orderData.country.value).then(function (data : any) {
            _this.shippingTypes = data;

            if (_this.shippingTypes.length)
                _this.shipping = _this.shippingTypes[0];
        });
    }

    ChangeShipping(s : any)
    {
        this.shipping = s;

        $('#js-delivery-list').slideUp(200);
    }

    StepIndicator(index : any)
	{
		$('.js-step-indicator').removeClass('active');
		$('.js-step-indicator').eq(index).addClass('active');
	}

	Step1() {
        this.StepIndicator(0);
        $('.js-step-2, .js-step-3').hide();
        $('.js-step-1').show();
	}

    Step2() {
        let fields = ["country", "email", "name", "surname"];

        var errors = 0;
        for (let field of fields) {
            console.log(field);
            if (this.orderData[field].require && !this.orderData[field].value.trim()) {
                this.orderData[field].class = "error";
                errors++;
            }
            if (field == "email")
			{
                if (!ValidEmail(this.orderData[field].value))
				{
                    this.orderData[field].class = "error";
                    errors++;
                }
			}
        }

        // if (errors > 0) {
        //     messageModal.Show("Ошибка", "Заполните обязательные поля формы");
        //     return;
        // }

        this.StepIndicator(1);
		$('.js-step-1, .js-step-3').hide();
		$('.js-step-2').show();
    }

    Step3() {
        let fields = ["city", "address", "postcode"];

        var errors = 0;
        for (let field of fields) {
            console.log(field);
            if (this.orderData[field].require && !this.orderData[field].value.trim()) {
                this.orderData[field].class = "error";
                errors++;
            }
        }

        // if (errors > 0) {
        //     messageModal.Show("Ошибка", "Заполните обязательные поля формы");
        //     return;
        // }

        this.StepIndicator(2);
        $('.js-step-2, .js-step-1').hide();
        $('.js-step-3').show();
    }

    ChangePayment(e : any, type : string)
    {
        //$('.js-payment-type > *').removeClass('active');
       //$(e.target).addClass('active');

        this.payment.type = type;
    }

    ToPayment()
    {
        $('#js-order-form').hide();
        $('.js-order-done').show().css('transform', 'scale(1)');
    }

    mounted() {
        messageModal.Init();

        this.getDeliveryCountries();
    }
}
